import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import {LetterList, NavMenu} from '@partssourceinc/react-ui-core';
import * as CompaniesStore from 'stores/Companies';
import {slugify} from 'utility';

import 'less/brands.less';
import {BrComponent} from '@bloomreach/react-sdk';

@withRouter
@connect((state) => ({oems: state.companies.oems}), CompaniesStore.actionCreators)
export default class Brands extends React.Component {
    static propTypes = {
        ...CompaniesStore.ActionShape,
        oems: CompaniesStore.OemsShape,
    };

    constructor(props) {
        super(props);
        this.state = {
            brands: [],
        };
    }

    componentWillMount() {
        const {oems, getOems} = this.props;

        if ((oems || []).length === 0)
            getOems();
        else
            this.getOemsFromProps(this.props);
    }

    componentWillReceiveProps(props) {
        this.getOemsFromProps(props);
    }

    getOemsFromProps(props) {
        const {oems} = props;

        const brands = (oems || []).filter(a => a.hasProducts).map(a => {
            return {
                name: a.name,
                slugName: slugify(a.name),
            }
        });

        this.setState({
            brands: brands,
        });
    }

    render() {
        const {brands} = this.state;

        return (<div className="brands-page">
            <NavMenu
                isPDP={true}
                className="ps-hidden-sm ps-hidden-xs header-nav-menu"
                options={[{label: 'Home', url: ''},
                    {label: 'All OEMs', url: ''}]} />
            <div className="brands-page_header">
                <h1>OEMs</h1>
            </div>
            <div className="top-cms-container ps-hidden-sm ps-hidden-xs">
                <BrComponent path={'main/container'} />
            </div>
            <LetterList items={brands} urlPath={'brands'} title={'OEMs'} />
        </div>);
    }
}
